const skillsSubSection = [
  {
    name: "Java",
    percentage: "60",
  },
  {
    name: "Javascript",
    percentage: "70",
  },
  {
    name: "HTML",
    percentage: "80",
  },
  {
    name: "CSS",
    percentage: "80",
  },
  {
    name: "MySQL",
    percentage: "70",
  },
  {
    name: "ReactJS",
    percentage: "80",
  },
];

export default skillsSubSection;
